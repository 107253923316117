import React, { useContext } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import DesContext from '../../../contexts/singleFlow/context.js';
import Step from '../../../automata/step/index.js';
import WorkflowContext from '../../../contexts/workflow/context.js';
import AutomataSidebar from '../AutomataSidebar/index.js';
import GetStarted from '../AutomataGetStarted/index.js';
import FastForwardControl from './FastForwardControl/index.js';
import StepDivider from './StepDivider/index.js';
import ProgressMonitor from './ProgressMonitor/index.js';
import LowerRightButton from './LowerRightButton/index.js';
import StylizedSummary from './StylizedSummary/index.js';
import AutomataPreviewMode from '../AutomataPreviewMode/index.js';

const AutomataProcessDesign = () => {
    const { manager } = useContext(DesContext);
    const workflowContextController = useContext(WorkflowContext);
    if (workflowContextController.activeWorkflow === null) { return <GetStarted /> }
    if (manager.previewMode) {
        return (
            <div style={{ display: "flex", height: "100vh", width: "100%" }}>
                <div style={{ position: "relative", height: "100vh", overflowY: "hidden", width: "100%" }}>
                   <AutomataPreviewMode/>
                </div>
                <AutomataSidebar />
            </div>
        );
    } else {
        return (
            <div style={{ display: "flex", height: "100vh", width: "100%" }}>
                <div style={{ position: "relative", height: "100vh", overflowY: "hidden", width: "100%" }}>
                    <div style={{ height: "100vh", overflowY: "scroll", paddingLeft: "22px", paddingRight: "10px" }}>
                        <div style={{ paddingTop: "60px" }} />
                        {manager.steps.map((step, index) => (
                            <div style={{ padding: "2px" }}>
                                <div style={{ display: "flex", width: "100%" }}>
                                    <div style={{ width: "90%" }}>
                                        <Accordion key={index + " " + step}>
                                            <StylizedSummary index={index} step={step} />
                                            <AccordionDetails>
                                                <Step step={step} stepId={step} stepIndex={index} stepData={{ ...manager.stepData[step] }} />
                                            </AccordionDetails>
                                        </Accordion>
                                    </div>
                                    <div>
                                        <FastForwardControl index={index} />
                                    </div>
                                </div>
                                <StepDivider index={index} />
                            </div>
                        ))}
                    </div>
                    <div>
                        <ProgressMonitor />
                        <LowerRightButton />
                    </div>

                </div>
                <AutomataSidebar />
            </div>
        );
    }
};

export default AutomataProcessDesign;
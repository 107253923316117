
import React from 'react';
import AddCommentIcon from '@mui/icons-material/AddComment';
import TextsmsIcon from '@mui/icons-material/Textsms';
import CheckboxCollector from './common/checkboxCollector';
import { MarkdownTable } from '../../../components/Lex/LexMarkdownTable';
import GenerativeStepCollector from '../../../components/Automata/GenerativeStepCollector';
import ContextCollector from '../../../components/Automata/ContextCollector';
import AutoAwesomeSharpIcon from '@mui/icons-material/AutoAwesomeSharp';
import TextFieldsOutlinedIcon from '@mui/icons-material/TextFieldsOutlined';

export const generativeStep = {
    overview: {
        name: 'generate',
        label: 'Produce LLM generated text?',
        sublabel: 'Utilize ChatGPT, Gemini, Mistral or another LLM to generate legal text.',
        icon: (<div style={{ position: 'relative', width: '100%', height: '100%', marginTop: '10px', marginLeft: '4px' }}>
            <AutoAwesomeSharpIcon style={{
                position: 'absolute', height: '16px', width: '16px',
                top: '4px', left: '16px',
                clipPath: 'polygon(0 0, 50% 0, 100% 100%, 0 100%)',
                transform: 'rotate(180deg)',
            }} />
            <TextFieldsOutlinedIcon style={{
                position: 'absolute',
                top: '5px',
                left: '0px',
            }} />
        </div>),
        click: ({ configureStep, stepData, errorDisplay }) => {
            configureStep.set({
                name: 'generativeStep',
                labelDisplay: "Generation",
                display: 'primaryOptions',
                initialized: true
            })
        }
    },
    outputDisplay: {
        enabled: true,
        collectorComponent: ({ configureStep, stepData, errorDisplay, testState }) => {
            console.log(testState)
            return <MarkdownTable markdown={testState?.[stepData?.varName] ? testState?.[stepData?.varName] : ""} />
        }

    },
    primaryOptions: {
        header: 'Generative Step',
        description: 'A generative step creates a conservation with the LLM. You may either create a fresh conversation or continue an existing one.  \n\n Under certain cirmcumstances, continuing an existing conversation allows the LLM to utilize the existing context to generate more accurate responses.',
        buttonOpts: [{
            label: 'Start a new conversation?',
            sublabel: '',
            icon: <AddCommentIcon />,

            click: ({ configureStep, stepData, errorDisplay }) => {
                configureStep.set({
                    useExistingContext: false,
                    display: 'tableCheck'
                })
            }
        },
        {
            label: 'Continue an existing conversation?',
            sublabel: '',
            icon: <TextsmsIcon />,
            disabled: ({ configureStep, stepData, errorDisplay }) => {
                return configureStep.priorConversations().length === 0
            },
            click: ({ configureStep, stepData, errorDisplay }) => {
                configureStep.set({
                    useExistingContext: true,
                    display: 'selectPreviousConversation'
                })
            }
        }


        ]
    },
    selectPreviousConversation: {
        header: 'Generative Step',
        description: 'Please select the conversation you wish to continue.',
        collectorComponent: (props) => <ContextCollector nextStep={'tableCheck'} {...props} />,
    },
    tableCheck: {
        header: 'Are you generating a table?',
        description: 'We can help you generate a table in a variety of formats.  Please select the checkbox below.',
        collectorComponent: (props) => <CheckboxCollector
            paramName={'isTable'}
            checkboxLabel={'Generate a table?'}
            nextStep={'writePrompt'}
            {...props} />
    },

    writePrompt: {
        header: 'Generative Step',
        description: 'Please write your LLM prompt and assign an output variable. You may use any text variables previously set at a preceeding step.',
        collectorComponent: (props) => <GenerativeStepCollector {...props} />,
    },
    default: {

    }


};
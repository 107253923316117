export const quickInput = (
    stepData,
    configureStep,
    inputLabel,
    target,
    helperText,
    multiline = null,
    rows = null
) => {
    return {
        targetName: target,
        inputLabel: inputLabel,
        value: stepData?.[target],
        multiline: multiline,
        rows: rows,
        onChange: (e) => {
            configureStep.set({
                [target]: e.target.value,
            })
        },
        helperText: helperText
    }
}

export const subcollectorQuickInput = (
    stepData, configureStep, conf,
    index, fieldData,
    inputLabel, target, helperText
) => ({
    inputLabel: inputLabel,
    targetName: target,
    onChange: conf(configureStep, stepData, index, target),
    value: fieldData?.[target],
    helperText: helperText
})
import AddCommentIcon from '@mui/icons-material/AddComment';
import TextsmsIcon from '@mui/icons-material/Textsms';
import ContextCollector from '../../../components/Automata/ContextCollector';
import GenerativeSeqCollector from '../../../components/Automata/GenerativeSeqCollector';
import AutoModeSharpIcon from '@mui/icons-material/AutoModeSharp';

export const generativeSequence = {
    overview: {
        name: 'generateSequence',
        label: 'Utilize an LLM to produce sequenced data.',
        sublabel: 'Utilize ChatGPT, Gemini, Mistral or another LLM to generate legal text.',
        icon: <AutoModeSharpIcon />,
        click: ({ configureStep, stepData, errorDisplay }) => {
            configureStep.set({
                name: 'generativeSequence',
                labelDisplay: "Seq. Gen",
                display: 'primaryOptions',
                initialized: true
            })
        }
    },
    outputDisplay: {
        enabled: true,
        collectorComponent: ({ configureStep, stepData, errorDisplay, testState }) => {
            console.log(testState)
            return <div> {testState?.[stepData?.varName] ? testState?.[stepData?.varName].map((line) => <div>{line.split('\n').map(innerLine => <div>{innerLine}<br /></div>)}<br /><br /></div>) : null}</div>
        }

    },
    primaryOptions: {
        header: 'Sequence Step',
        description: 'Using a sequence step, you can create a sequence of generated texts, with each element of the sequence reprsenting a portion of a sequential process.',
        buttonOpts: [{
            label: 'Start a new conversation?',
            sublabel: '',
            icon: <AddCommentIcon />,

            click: ({ configureStep, stepData, errorDisplay }) => {
                configureStep.set({
                    useExistingContext: false,
                    display: 'writePrompt'
                })
            }
        },
        {
            label: 'Continue an existing conversation?',
            sublabel: '',
            icon: <TextsmsIcon />,
            disabled: ({ configureStep, stepData, errorDisplay }) => {
                return configureStep.priorConversations().length === 0
            },
            click: ({ configureStep, stepData, errorDisplay }) => {
                configureStep.set({
                    useExistingContext: true,
                    display: 'selectPreviousConversation'
                })
            }
        }


        ]
    },
    selectPreviousConversation: {
        header: 'Sequence Step',
        description: 'Please select the conversation you wish to continue.',
        collectorComponent: (props) => <ContextCollector {...props} />,
        click: ({ configureStep, stepData, errorDisplay }) => {
            configureStep.set({
                display: 'writePrompt'
            })
        },
    },
    writePrompt: {
        header: 'Sequence Step',
        description: 'You may write a sequential LLM prompt. \n\nPlease note, these prompts will be run in parallel, and each prompt will differ only from the rest by the use of implicit $step variable. The $step variable represents an index in the generated sequence.\n\n For example, if you write "Step ($step) involves...", the LLM will recieve the prompts \n\n"Step (1) involves..."\n"Step (2) involves..."\n"Step (3) involves...", etc.',
        collectorComponent: (props) => <GenerativeSeqCollector {...props} />,
    },
    default: {

    }


};
import { Container, Typography, Grid, useTheme } from '@mui/material';
import { useRef, useEffect,useLayoutEffect, useState } from 'react';
import { Logo } from '../../logoComponent';
import QuickStartContext from '../../../contexts/quickstart/context';
import { Button, Box } from '@mui/material';
import { useContext } from 'react';
import NET from 'vanta/dist/vanta.net.min'
import WorkflowContext from '../../../contexts/workflow/context';

const WelcomePage = (props) => {
    const { showQuickStart, setShowQuickStart } = useContext(QuickStartContext);
    const { workflows, manager } = useContext(WorkflowContext);
    const [vantaEffect, setVantaEffect] = useState(null)
    const theme = useTheme();
    const myRef = useRef(null)
    const headerBackgroundColor = theme.palette.background.paper;
    useEffect(() => {
      if (!vantaEffect) {
        setVantaEffect(NET({
          el: myRef.current,
          mouseControls: false,
          touchControls: false,
          gyroControls: false,
          minHeight: 200.00,
          minWidth: 200.00,
          scale: 1.00,
          scaleMobile: 1.00,
          color: 0xd1e4e6,
          backgroundColor: headerBackgroundColor,
          points: 20.00,
          showDots: false
        }))
      }
      return () => {
        if (vantaEffect) vantaEffect.destroy()
      }
    }, [vantaEffect])
    return (
        <div ref={myRef} style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden scroll",
        }}>
            <Box
                sx={{
                    width: '80%',
                    maxWidth: '400px',
                    textAlign: 'center',
                    padding: '20px',
                    border: '1px solid #ccc',
                    borderRadius: '8px',
                    backgroundColor: 'background.paper',
                }}
            >
                    <div style={{
            height: "150px",
            width: "100%",
            backgroundColor: "white",
            color: "black",
            paddingTop: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        }}>

            {/* <DiagonalSplitter width={50} height={80} /> */}
            <Logo
                setOpenPage={() => { }}
                format="text-alt2"
                style={{ flex: '0 1 fit-content' }} /></div>
                <Typography variant="body1" gutterBottom>
                    This is where your active automations will appear.
                </Typography>
                <Button style={{width:"300px",margin:"5px"}} variant="contained" color="primary" onClick={manager.createWorkflow}>
                    Build a new automation
                </Button>
                <Button style={{width:"300px",margin:"5px"}} variant="contained" color="primary" onClick={()=>{setShowQuickStart(true)}}>
                    Quickstart Guide
                </Button>
            </Box>
        </div>
    );
}

export default WelcomePage;
import Collector from '../Collector';
import { quickInput } from '../utilities';

const GenerativeRAGCollector = ({ configureStep, stepData, errorDisplay }) => {
    return <Collector
        // titleText={"LLM Prompt"}
        descriptionText={"Please write your LLM prompt and assign an output variable. You may use any text variables previously set at a preceeding step."}
        inputFields={[
            quickInput(stepData,configureStep, "Output Variable", "varName", "This is the variable the output will be stored in."),
            quickInput(stepData,configureStep, "File Store Variable", "fileVarName", "Any files queries will be stored in this variable."),
            quickInput(stepData,configureStep, "LLM Prompt", "prompt", "This is the prompt/command that will be given to the LLM",true,5)
           ]}
    />
};

export default GenerativeRAGCollector;
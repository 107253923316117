
import AddIcon from '@mui/icons-material/Add';
import { Typography, FormControl, Select, MenuItem, InputLabel } from '@mui/material';
import TextField from '@mui/material/TextField';
import LeakAddIcon from '@mui/icons-material/LeakAdd';

const conf = (configureStep, stepData, index, param) => (e) => {
    let newCollectedFields = [...stepData.collectedFields]
    newCollectedFields[index][param] = e.target.value
    configureStep.set({
        collectedFields: newCollectedFields,
    })
}
const DataCollector = ({ configureStep, stepData, errorDisplay }) => {
    const testOpts = ["Text Input", "Checkbox", "Select"]
    return (
        <div style={{}}>
            {stepData.collectedFields.map((fieldData, index) => {
                return <div style={{ marginTop: "5px", border: "1px solid black", backgroundColor: "rgb(0,0,0,.1)", borderRadius: "8px", paddingTop: "15px", paddingBottom: "15px", paddingLeft: "15px" }}>
                    <div style={{ paddingBottom: "20px" }}>
                        <Typography variant="h6">Form Input</Typography>
                    </div>

                    <div style={{ paddingBottom: "20px" }}>
                        <Typography variant="body1">Please provide information abou the data you wish to collect.</Typography>
                    </div>
                    <div>
                        <FormControl variant="filled" style={{ width: "150px" }}>
                            <InputLabel id="demo-simple-select-filled-label">Type</InputLabel>
                            <Select
                                value={fieldData.type}
                                onChange={(e) => {
                                    conf(configureStep, stepData, index, "type")(e)
                                    if (e.target.value === "Select") {
                                        conf(configureStep, stepData, index, "sample")({ target: { value: fieldData.selectOptions.split(",")[0] } })
                                    }
                                    if (e.target.value === "Checkbox") {
                                        conf(configureStep, stepData, index, "sample")({ target: { value: false } })
                                    }
                                    if (e.target.value === "Text Input") {
                                        conf(configureStep, stepData, index, "sample")({ target: { value: "" } })
                                    }
                                    
                                }}
                                displayEmpty
                                labelId="demo-simple-select-filled-label"
                                id="demo-simple-select-filled"
                            >
                                <MenuItem value="" disabled>
                                    Select an option
                                </MenuItem>
                                {testOpts.map((selStr) => (
                                    <MenuItem key={selStr} value={selStr}>
                                        {selStr}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    {fieldData.type === "Select" ? <div>
                        <TextField
                            id="filled-basic"
                            label="Selection Options"
                            value={fieldData.selectOptions}
                            onChange={conf(configureStep, stepData, index, "selectOptions")}
                            variant="filled"
                            helperText="Please seperate the selection options by a comma."
                        />
                    </div> : null}
                    <div>
                        <TextField
                            id="filled-basic"
                            label="Label"
                            value={fieldData.label}
                            onChange={conf(configureStep, stepData, index, "label")}
                            variant="filled"
                            helperText="This is the label of the field that will be displayed to the end-user."
                        />
                    </div>
                    <div>
                        <TextField
                            id="filled-basic"
                            label="Variable Name"
                            value={fieldData.varName}
                            onChange={conf(configureStep, stepData, index, "varName")}
                            variant="filled"
                            helperText="This is the variable name that will be used to store the input data."
                        />
                    </div>

                    <div>
                        {fieldData.type === "Select" ? <FormControl variant="filled" style={{ width: "150px" }}>
                            <InputLabel id="demo-simple-select-filled-label">Sample Input</InputLabel>
                            <Select
                                value={fieldData.sample}
                                onChange={conf(configureStep, stepData, index, "sample")}
                                displayEmpty
                                labelId="demo-simple-select-filled-label"
                                id="demo-simple-select-filled"
                            >
                                <MenuItem value="" disabled>
                                    Select sample input
                                </MenuItem>
                                {fieldData.selectOptions.split(",").map((selStr) => (
                                    <MenuItem key={selStr} value={selStr}>
                                        {selStr}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl> : <TextField
                            id="filled-basic"
                            label="Sample Input"
                            value={fieldData.sample}
                            onChange={conf(configureStep, stepData, index, "sample")}
                            variant="filled"
                            helperText="hahaha"
                        />}
                    </div>
                </div>
            })}
        </div>)
};




export const apiStep = {
    overview: {
        name: 'uploadStep',
        label: 'Perform a search on an existing legal API',
        sublabel: 'Search results enable superior contextual generation of legal text.',
        icon: <LeakAddIcon />,
        click: ({ configureStep, stepData, manager, errorDisplay }) => {
            configureStep.set({
                name: 'formDataStep',
                labelDisplay: "Form Data",
                collectedFields: [],
                display: 'primaryOptions',
                initialized: true
            })
        }
    },
    primaryOptions: {
        header: 'Data Collection Step',
        description: 'You can collect data from the user for use in a later generative step.  Data can be collected from the user in a variety of ways, including text fields, radio buttons, and checkboxes. You can collect up to ten input fields per step.' +
            `\n\nAll user submitted data collected in this step will be available for your prompts in sequential steps. It can be added to a prompt by using the variable name you assign to each field and prefixing it with "$".` +
            `\n\nFor example, if you collect a user's name and assign it the variable name "name", you can use the variable "$name" in your prompt to reference the user's name.`,
        collectorComponent: (props) => <DataCollector {...props} />,
        buttonOpts: [{
            label: 'Add a new form field',
            sublabel: '',
            icon: <AddIcon />,
            click: ({ configureStep, stepData, errorDisplay }) => {
                configureStep.set({
                    collectedFields: [...stepData.collectedFields, {
                        type: 'text',
                        label: '',
                        varName: '',
                        selectOptions: "option1,option2",
                        required: false,
                        sample: ""
                    }],
                })
            }
        }],
        inputOpts: [],
    },
    default: {

    }


};
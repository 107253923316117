import {useContext } from 'react';
import DesContext from '../../../contexts/singleFlow/context';
import LexButton from '../../Lex/LexButton';

const DocGenDownload = ({ configureStep, stepData, errorDisplay, stepIndex, stepId }) => {
    let { manager} = useContext(DesContext);
    return <div>
        <LexButton variant="contained" color="primary" onClick={manager.downloadCompletedTemplate(stepData)}>Download</LexButton>
    </div>
}

export default DocGenDownload;
import Collector from "../Collector";
import { quickInput } from "../utilities";

const GenerativeSeqCollector = ({ configureStep, stepData, errorDisplay }) => {
    return <Collector
        inputFields={[
            quickInput(stepData,configureStep, "Output Variable", "varName", "This is the variable the output will be stored in."),
            quickInput(stepData,configureStep, "Starting Index", "startingIndex", "This is the starting index for the sequence"),
            quickInput(stepData,configureStep, "Ending Index", "endingIndex", "This is the ending index for the sequence"),
            quickInput(stepData,configureStep, "LLM Prompt", "prompt", "This is the prompt/command that will be given to the LLM",true,5)
           ]}
    />
};

export default GenerativeSeqCollector;

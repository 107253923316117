import * as React from "react";

function SvgComponent(props) {
  return (
    <svg fill="#000000" height="800px" width="800px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 291.955 291.955" xmlSpace="preserve">
      <g>
        <path d="M230.473,0.021L60.748,0L4.874,55.873v236.082h282.207V55.041L230.473,0.021z M158.042,91.081l71.27-71.269l39.008,37.911
		l-71.817,71.819L158.042,91.081z M171.489,125.741l-24.053,48.922l-24.053-48.922l24.053-24.053L171.489,125.741z M147.436,80.474
		L81.965,15.002L212.89,15.02L147.436,80.474z M63.854,18.106l72.975,72.975l-38.461,38.461L25.394,56.566L63.854,18.106z
		 M19.874,72.261l78.494,78.494l13.808-13.808l26.902,54.715l-41.937,85.294H19.874V72.261z M147.436,208.66l33.579,68.295h-67.158
		L147.436,208.66z M197.73,276.955l-41.937-85.294l26.902-54.715l13.808,13.808l75.578-75.578v201.778H197.73V276.955z"/>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;

export function useFileHandlers(importFile) {
    function downloadObjectAsJson(obj, filename) {
        const jsonString = JSON.stringify(obj, null, 2); // Indented JSON string for readability
        const blob = new Blob([jsonString], { type: "application/json" });
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a); // Required for this to work in FireFox
        a.click();
        URL.revokeObjectURL(url);
        document.body.removeChild(a);
    }

    function loadJsonFile(callback) {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = '.json';

        input.onchange = (e) => {
            const file = e.target.files[0];

            if (!file) {
                return;
            }

            const reader = new FileReader();
            reader.onload = (e) => {
                try {
                    const obj = JSON.parse(e.target.result);
                    callback(obj);
                } catch (error) {
                    console.error('Error parsing JSON:', error);
                }
            };
            reader.readAsText(file);
        };

        input.click();
    }


    function handleFileLoad() {
        console.log("aaha")
        loadJsonFile((loadedObject) => {
            importFile(loadedObject['flow'])
       });
    }

    return { handleFileLoad, downloadObjectAsJson }
}
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import {
    FormControlLabel,
    useTheme
} from '@mui/material';
import {useState,useContext } from "react";
import DesContext from '../../../contexts/singleFlow/context';
import LexSimpleSelect from '../../Lex/LexSimpleSelect';

const AutomataFormInput = ({stepId,activeStepData,field}) => {
    const {manager} = useContext(DesContext);
    const {configureStep,testState} = manager.createStepOperationsById(stepId)
    console.log(field)
    const setField= (e) => {
        configureStep.setTest({
            [field.varName]: e.target.value,
        })
    }
    const handleChange = (event) => {
        if(field.type==="Text Input"){
            setField(event)
        }
        if(field.type==="Checkbox"){
            setField(event)
        }
        if(field.type==="Select"){
            setField(event)}
    };

    switch (field.type) {
        case 'Text Input':
            return <TextField
                label={field?.label}
                required={false}
                onChange={handleChange}
                defaultValue={testState?.[field.varName] ? testState?.[field.varName] : ""}
                sx={{backgroundColor:'rgb(0,0,0,0)',width:"500px"}}
                variant="filled"
                // fullWidth
                color="primary"
                inputProps={{ maxLength: 20000 }}
                type="text" />
        // case 'textArea':
        //     return <TextField
        //         label={props?.description}
        //         required={props?.required}
        //         onChange={props.onChange}
        //         defaultValue={""}
        //         inputProps={{ maxLength: 20000 }}
        //         multiline
        //         rows={6}
        //         fullWidth
        //         variant="filled"
        //         color="primary"
        //         type="text" />
        case 'Checkbox':
            return <div style={{paddingLeft:"10px"}}><FormControlLabel

                label={field?.label}
                required={false}
                onChange={(e) => handleChange({ target: { value: e.target.checked } })}
                control={
                    <Checkbox
                        color="primary" />
                } />
            </div>
        case 'Select':
            return<LexSimpleSelect options={field.selectOptions.split(",")}
            handleChange={handleChange}
            value={testState?.[field.varName] ? testState?.[field.varName] : ""}
            title={field.label} />

                    // return <div style={{paddingLeft:"10px"}}><FormControlLabel
        
                    //     label={field?.label}
                    //     required={false}
                    //     onChange={(e) => handleChange({ target: { value: e.target.checked } })}
                    //     control={
                    //         <Checkbox
                    //             color="primary" />
                    //     } />
                    // </div>
        default:
            return <div></div>

    }
    return <div>awf</div>
}

export default AutomataFormInput;
import Collector from '../Collector';
import { quickInput } from '../utilities';

const GenerativeStepCollector = ({ configureStep, stepData, errorDisplay }) => {
    return <Collector
        inputFields={[
            quickInput(stepData, configureStep, "Output Variable", "varName", "This is the variable the output will be stored in."),
            quickInput(stepData, configureStep, "LLM Prompt", "prompt", "This is the prompt/command that will be given to the LLM",true,5)
        ]}
    />
};

export default GenerativeStepCollector;
import React, { useEffect, useState, useContext } from "react";
import {
    Typography,
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BlurOnIcon from '@mui/icons-material/BlurOn';
import correctLabel from "./label";
import { useTheme } from '@mui/material';

const ExpandableAccordion = ({ newKeys, idx, summary, longSummary, details, enableEditing, underlyingId }) => {
    const theme = useTheme();
    function checkArray(x) {
        if (Array.isArray(x) && x.length > 0) {
            return x;
        } else {
            return [];
        }
    }
    
    return <Accordion
        style={{ /* TODO: backgroundColor: "#182223", */ marginTop: idx > 0 ? "15px" : 'none', boxShadow: 'none', }}>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
        >
            <span style={{ transform: "scale(0.65)", marginTop: "-5px", marginBottom: "-5px", marginLeft: "-8px" }} ><Button
                // color="complement"
                sx={{ color: "primary.dark", borderColor: "primary.dark" }}
                style={{ zIndex: "2000", maxHeight: "23px" }}
                variant="outlined"
                onClick={(e) => {
                    e.preventDefault()
                    enableEditing(underlyingId, longSummary)
                }}>Edit</Button> </span>
                            <Typography style={{ fontSize: ".8em" }}><b>{summary}</b></Typography>
                            {checkArray(newKeys).includes(underlyingId) &&<Typography style={{ fontSize: ".8em",marginLeft:"10px", color:theme.palette.info.main}}>(New)</Typography>}


        </AccordionSummary>
        <AccordionDetails>

            <Typography style={{ whiteSpace: 'pre-wrap' }}>
                {details}
            </Typography>
        </AccordionDetails>
    </Accordion>
};

const Expander = ({ primaryKey, newKeys, idx, parsedClaimList, enableEditing, value, allVariables }) => {
    const labels = { "claim1": "Independent Claim 1 ", "claim2": "Independent Claim 2 ", "claim3": "Independent Claim 3 " }
    if (Array.isArray(parsedClaimList[primaryKey]) && parsedClaimList[primaryKey].length > 0) {
        return <Accordion
            style={{ /* TODO: backgroundColor: "#182223", */ marginTop: idx > 0 ? "15px" : 'none', boxShadow: 'none', border: "1px solid black", borderRadius: "5px" }}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography style={{ fontSize: ".8em",paddingLeft:"10px "}}><b>{labels[primaryKey]}</b></Typography>
            </AccordionSummary>
            <AccordionDetails>
                {parsedClaimList[primaryKey].map((innerObject, idxInner) => {
                    let actualKey = Object.keys(innerObject)[0]
                    let skip = false
                    const skipKeys = ['independentClaim', 'indClaim1Dets']
                    skipKeys.forEach((testKey) => {
                        if (actualKey.startsWith(testKey) && (actualKey.endsWith("_2") || actualKey.endsWith("_3"))) {
                            skip = true
                        }
                    })
                    if (skip && allVariables['mirrorClaim'] === "1") {
                        return null
                    }
                    let details = value[actualKey]
                    if(actualKey.startsWith("independentClaim1")){
                        details = details.replaceAll("\n", "\n\n")
                    }
                    return <ExpandableAccordion
                        idx={idxInner}
                        newKeys={newKeys}
                        enableEditing={enableEditing}
                        underlyingId={actualKey}
                        summary={correctLabel(allVariables, actualKey, true)}
                        longSummary={correctLabel(allVariables, actualKey, false)}
                        details={details} />
                })}

            </AccordionDetails>
        </Accordion>
    }
    if (typeof parsedClaimList[primaryKey] === 'object' && !Array.isArray(parsedClaimList[primaryKey])) {
        return (<ExpandableAccordion
            idx={idx}
            enableEditing={enableEditing}
            underlyingId={primaryKey}
            summary={correctLabel(allVariables, primaryKey, true)}
            longSummary={correctLabel(allVariables, primaryKey, false)}
            details={value[primaryKey]} />)
    } else {
        return null
    }
}

const ViewerNew = (props) => {
    const { parsedClaimList, value, enableEditing, allVariables } = props;
    const primaryKeys = Object.keys(parsedClaimList)
    console.log(props.newKeys)
    console.log(primaryKeys)
    if (Object.keys(value).length === 0) {
        return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: "column", height: '100%' }}>
            <div><BlurOnIcon sx={{ fontSize: "48px" }} />
            </div>
            <Typography variant="h6" align="center">
                ...nothing yet...
            </Typography>
            <Typography variant="body1" align="center" style={{ marginTop: "10px" }}>
                Your draft will be displayed here as it is prepared.
            </Typography>
        </div>
    }
    return (<div >{primaryKeys.map((key, idx) => (<Expander newKeys={props.newKeys} idx={idx} primaryKey={key} {...props} />))}</div>)

}

export default ViewerNew
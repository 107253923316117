import React, {useState} from "react";
import {Box,Tab,Tabs,useMediaQuery,useTheme} from '@mui/material';
import Settings from "./Settings";
import Files from "./Files";
import LexTabMenu from "../../Lex/LexTabMenu";

const AutomataSidebar = (props) => {
    const [value, setValue] = useState(0);
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const outerSx={
        width: isMobile ? '80vw' : '100%',
        maxWidth: isMobile ? 'auto' : '12cm',
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '100vh',
        height: "100vh",
        backgroundColor: theme.palette.backgroundSecondary.default,
    }
    const changeHandler = (e, newValue) => { setValue(newValue) }
    return (
        <Box sx={outerSx}>
            <LexTabMenu 
            optionList={[{ label: "Settings" }, { label: "Files" }]} 
            sidebarType={value} 
            setSidebarType={setValue}
            changeHandler={changeHandler}
            />
            {value === 0 ? <Settings/>:null}
            {value === 1 ? <Files stepId={props.stepId}/>:null}
        </Box>
    )
}

export default AutomataSidebar;
import React, { useContext } from 'react';
import DesContext from '../../../contexts/singleFlow/context.js';
import LexTypography from '../../Lex/LexTypography/index.js';
import styled from 'styled-components';
import AutomataFormInput from '../AutomataFormInput/index.js';
import { Alert} from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import LexButton from '../../Lex/LexButton/index.js';
import { MarkdownTable } from '../../Lex/LexMarkdownTable/index.js';
import { useTheme } from '@mui/styles';

const AutomataPreviewMode = () => {
    const { manager } = useContext(DesContext);
    const pActiveStepData = manager.stepData?.[manager.previewModeActiveStep];
    const stepIndex = manager.previewStepIndex ? manager.previewStepIndex : 0
    const title = pActiveStepData?.suppliedName ? pActiveStepData?.suppliedName : "(No Name)"
    const description = pActiveStepData?.suppliedDescription ? pActiveStepData?.suppliedDescription : "(No Description)"
    const collectedFields = pActiveStepData?.collectedFields ? pActiveStepData?.collectedFields : []
    const {testState} = manager.createStepOperationsById(manager.previewModeActiveStep)
    const outputVariable = pActiveStepData?.varName ? pActiveStepData?.varName : null
    const theme = useTheme();
    return (<OuterContainer>
        <div style={{ paddingTop: "10px" }}>
            <Alert variant="filled" severity="warning">
                You are currently in preview mode.
            </Alert>
        </div>
        <FirstInnerContainer>
            <SecondInnerUpperContainer>
                <ThirdInnerUpperContainer>
                    <LexTypography variant="h5" style={{ paddingBottom: '1em' }}>{title} (Step {stepIndex+1})</LexTypography>
                    {description.split("\\n").map((i, key) => {
                        return <LexTypography variant="body1" gutterBottom
                            style={{
                                margin: key > 0 ? '20px 0px 0px' : '0px'
                            }}>{i}<br /></LexTypography>
                    })}
                    {pActiveStepData?.name === "formDataStep" ? <div style={{ paddingTop: "30px" }}>
                        {collectedFields.map((field) => {
                            return <div
                                style={{
                                    margin: '5px 0px 20px',
                                    width: 'auto',
                                }}>
                                <AutomataFormInput stepId={manager.previewModeActiveStep} activeStepData={pActiveStepData} field={field} />
                            </div>
                        })}
                    </div> : null}
                    {pActiveStepData?.name !== "formDataStep" ? <div style={{ maxHeight:"500px",backgroundColor:theme.palette.background.paper,border:"dashed 1px rgb(0,0,0,.3)",borderRadius:"5px",overflowY:"scroll", marginTop: "30px",padding:"10px" }}>
                        <MarkdownTable markdown={testState?.[outputVariable]}/>
                    </div> : null}
                </ThirdInnerUpperContainer>
            </SecondInnerUpperContainer>
        </FirstInnerContainer>
        <div style={{
            position: 'relative',
            height: '100%',
            width: '40vw',
        }}>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
            }}>
                <div style={{
                    marginBottom: '1em'
                }}>
                    {pActiveStepData?.name !== "formDataStep" ? <LexButton
                        style={{ marginLeft: "15px" }}
                        onClick={manager.executePreviewSequence(manager.previewModeActiveStep,true)}
                        variant="contained"
                        color="primary"
                        type="submit"
                        endIcon={<ArrowRightIcon />}
                    >
                        Generate
                    </LexButton>:null                    
                
                }
                    {pActiveStepData?.name === "formDataStep" ? <LexButton
                        style={{ marginLeft: "15px" }}
                        onClick={manager.executePreviewSequence(manager.previewModeActiveStep,false)}
                        variant="contained"
                        color="primary"
                        type="submit"
                        endIcon={<ArrowRightIcon />}
                    >
                        Continue
                    </LexButton>:<LexButton
                        style={{ marginLeft: "15px" }}
                        onClick={manager.simpleAdvanceSeq(manager.previewModeActiveStep,false)}
                        variant="contained"
                        color="primary"
                        type="submit"
                        endIcon={<ArrowRightIcon />}
                    >
                        Continue
                    </LexButton>}
                </div>
            </div>
        </div>
    </OuterContainer>)

};

const OuterContainer = styled.div`
    display: flex;
    flex-grow: 1;
    height: 100%;
    width: auto;
    overflow-y: scroll;
    scrollbar-gutter: stable both-edges;
    flex-direction: column;
    overflow-x: hidden;
    padding-right: ;3em;
`;

const FirstInnerContainer = styled.div`
    width: 40vw;
    display: flex; 
    flex-direction: column;
    padding-left: 20px;
`;

const SecondInnerUpperContainer = styled.div`
    width: auto;
    display: flex;
    padding: 3em 0px 2em;
    flex-direction: column;
`;

const ThirdInnerUpperContainer = styled.div`
    flex: 1 1 auto;
    height: 100%; 
    width: 100%;
    paddingTop: 0px;
    display: flex;
    flex-direction: column;
`;



export default AutomataPreviewMode;
import '../App.css';
import React, { useEffect, useState, useContext, useLayoutEffect } from "react";
import { FormPage } from '../components/formPage';
import { SideCard } from '../components/cards';
import NodeEdgeContext from '../contexts/figureDesign/context.js';
import {
    Box,
    Tab,
    Tabs,
    CircularProgress, Modal,
    SwipeableDrawer,
    useMediaQuery,
    useTheme
} from '@mui/material';
import { useErrorModal } from '../contexts/error';
import EndPage from './endpage';
import Sidebar from '../figures/sidebar';
import Figures from '../figures';
import ViewerNew from './patentView';
import BackButtonModal from './backButtonModal';
import EditableModal from './editModal';
import ClearContext from '../contexts/figureDesignClearContext/context.js';
import app_name from '../constants/name.js';
import APIContext from '../contexts/api/context.js';
export const Sidebarify = (props) => {
    
    const drawerOpen = props.drawerOpen;
    const toggleDrawer = props.toggleDrawer;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const sidebarContent = props.children
    return isMobile ? (
        <SwipeableDrawer
            anchor="right"
            open={drawerOpen}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
        >
            {sidebarContent}
        </SwipeableDrawer>
    ) : sidebarContent
}

const SidebarWrapper = (props) => {
    const { clearVer } = useContext(ClearContext);
    return (<Sidebar key={clearVer} patentId={props.patentId} />)
}

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}


const BetaDisplay = (props) => {
    const [value, setValue] = useState(0);
    const showError = useErrorModal()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleChange = (event, newValue) => {
        // setValue(newValue);
        if (newValue !== 0 && props.patentStep !== "end") {
            showError("Please complete your patent first.")
        } else {
            if (newValue === 2) {
                showError("AI assisted Office Action is launching mid-April. Stay tuned!")
            } else {
                if (!isMobile) {
                    setValue(newValue);
                } else {
                    showError("Please use "+app_name+" on a deskop to access the figure generation tool.")
                }
            }
        }
    };
    useEffect(() => {
        if (value === 1) {
            props.setActiveFigures(true)
        } else {
            props.setActiveFigures(false)
        }

    }, [value])
    return (
        <Box sx={{
            width: isMobile ? '80vw' : '100%',
            display:"flex",
            flexDirection: "column",
            maxWidth: isMobile ? 'auto' : '12cm',
            maxHeight: '100vh',
            backgroundColor: theme.palette.backgroundSecondary.default,
        }}>
            <Box sx={{ color: 'primary.children', borderBottom: 1, borderColor: 'divider' }}>
                <Tabs textColor="inherit"
                    TabIndicatorProps={{ sx: { bgcolor: "primary.children" } }}
                    value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab style={{ color: "white" }} label="Draft"
                        {...a11yProps(0)} />
                    <Tab style={{ color: "white" }} label="Figures"
                        {...a11yProps(1)} />
                </Tabs>
            </Box>
            {value === 0 ?
                <SideCard style={{  flex: 1,height: "90vh" }}>
                    <ViewerNew newKeys={props.newKeys} parsedClaimList={props.parsedClaimList} allVariables={props.allVariables} enableEditing={props.enableEditing} value={props.variables} />
                </SideCard>
                : null}

            {value === 1 ?
                <><SidebarWrapper patentId={props.patentId} /></>
                : null}
        </Box>
    )
}

const LoadingModal = (props) => (
    <Modal
        open={props.loading}
        aria-labelledby="loading-modal-title"
        aria-describedby="loading-modal-description"
    >
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress />
        </Box>
    </Modal>
);


const SequenceHandler = (props) => {
    const {api} = useContext(APIContext)
    const [backModalOpen, setBackModalOpen] = useState(false);
    const [loading, setLoading] = useState(false)
    const [currentState, setCurrentState] = useState({})
    const theme = useTheme();
    const [editMode, setEditMode] = useState(false)
    const [newKeys, setNewKeys] = useState([])
    const [editVariable, setEditVariable] = useState("")
    const [editDisplayTxt, setEditDisplayTxt] = useState("")
    const [currentStep, setCurrentStep] = useState(null)
    const [variables, setVariables] = useState(props.variables)
    const [fullSequenceData, setFullSequenceData] = useState({})
    const { figuresActive, setActiveFigures } = useContext(NodeEdgeContext);
    const showError = useErrorModal()

    useEffect(() => { setLoading(false) }, [props.parentVer])

    const enableEditing = (varLabel, varDisp) => {
        setEditMode(true)
        setEditVariable(varLabel)
        setEditDisplayTxt(varDisp)
    }
    const submitEdits = (text) => {
        setEditMode(false)
        api('/ptntst/editPatentVar', { patentId: props.patentId, variable: editVariable, editText: text }).then(res => {
            setEditVariable("")
            setEditDisplayTxt("")
            props.setNode(res.data.currentNode)
            setVariables(res.data.variables)
        })

    }
    const [drawerOpen, setDrawerOpen] = useState(false);
    useEffect(() => {
        props.setSidebarUsable(true)
        return () => {
            props.setSidebarUsable(false)
        }
    }, [])
    const updateState = (key, val) => {
        setCurrentState(currentState => ({ ...currentState, [key]: val }))
    }

    const continueSequence = (newState) => {
        setTimeout(() => {
            setLoading(true)
            api('/ptntst/fullEvaluation', { patentId: props.patentId, inputData: currentState }).then(res => {
                props.setNode(res.data.nextNode)
                setVariables(res.data.variables)
                setNewKeys(res.data.newKeys)
                console.log("HAHA")
                console.log(res.data)
                props.forceVerUpdate(() => { setLoading(false) }, res.data.nextNode, res.data.variables)
            }).catch((e) => {
                setLoading(false)
                showError("Error(" + e.response.status + "):" + e.response.data)
            })
        }, 250)
    }
    const backButtonHit = () => {
        setLoading(true)
        api('/ptntst/backButton', { patentId: props.patentId }).then(res => {
            setLoading(false)
            props.setNode(res.data.nextNode)
            setVariables(res.data.variables)
        })
            .catch(e => {
                setLoading(false)
                showError("Error(" + e.response.status + "):" + e.response.data)
            });
    }

    let filteredVars = {}
    Object.keys(variables).map((key) => {
        ['prefixIndClaim', 'independentClaim', 'dependentClaims', 'indClaim1Dets', 'detailDescriptions', 'bgSection', 'abstract', 'field'].map((validPrefix) => {
            if (key.startsWith(validPrefix)) {
                filteredVars[key] = variables[key]
            }
        })
    })
    let output = {
        'claim1': [],
        'claim2': [],
        'claim3': []
    };
    Object.keys(filteredVars).forEach((key) => {
        if (key.endsWith("_2")) {
            output.claim2.push({ [key]: filteredVars[key] });
        } else if (key.endsWith("_3")) {
            output.claim3.push({ [key]: filteredVars[key] });
        } else if (['bgSection_00', 'abstract_00', 'field_00'].includes(key)) {
            output[key] = { [key]: filteredVars[key] };
        } else {
            if (!key.startsWith('prefixIndClaim')) {
                output.claim1.push({ [key]: filteredVars[key] });
            }
        }
    });
    const sortClaims = (claims) => {
        const order = ['independentClaim1_00', 'dependentClaims_00', 'indClaim1Dets_', 'detailDescriptions_', 'independentClaimSummary1_00'];
        return claims.sort((a, b) => {
            const keyA = Object.keys(a)[0];
            const keyB = Object.keys(b)[0];
            const indexA = order.findIndex((item) => keyA.startsWith(item));
            const indexB = order.findIndex((item) => keyB.startsWith(item));
            if (indexA === indexB) {
                return keyA.localeCompare(keyB);
            }
            return indexA - indexB;
        });
    };
    output.claim1 = sortClaims(output.claim1);
    output.claim2 = sortClaims(output.claim2);
    output.claim3 = sortClaims(output.claim3);

    if (variables?.['prefixIndClaim1']) {
        output.claim1 = [{ 'prefixIndClaim1': variables['prefixIndClaim1'] }, ...output.claim1]
        output.claim2 = [{ 'prefixIndClaim2': variables['prefixIndClaim2'] }, ...output.claim2]
        output.claim3 = [{ 'prefixIndClaim3': variables['prefixIndClaim3'] }, ...output.claim3]
    }
    const parsedClaimList = output
    if (props.currentlySelectedNode.id === "end" && !!props.paid) {
        if (figuresActive) {
            return (
                <div style={{ display: 'flex', width: "100%", height: '100%' }}>
                    <LoadingModal loading={loading} />
                    <Figures />
                    <Sidebarify drawerOpen={props.drawerOpen} toggleDrawer={props.toggleDrawer}>
                        <BetaDisplay
                            key={props.patentId + props.currentlySelectedNode.id}
                            activeSequence={fullSequenceData.activeSequence}
                            patentId={props.patentId}
                            parsedClaimList={parsedClaimList}
                            enableEditing={enableEditing}
                            patentStep={props.currentlySelectedNode.id}
                            currentStep={currentStep}
                            newKeys={newKeys}
                            variables={filteredVars}
                            allVariables={variables}
                            currentState={currentState}
                            figuresActive={figuresActive}
                            setActiveFigures={setActiveFigures}
                        />
                    </Sidebarify>

                </div>)
        } else {

            return (<div style={{ display: 'flex', width: "100%", height: '100%' }}>
                <LoadingModal loading={loading} />
                <EndPage patentId={props.patentId} downloadPatentWord={props.downloadPatentWord} downloadFiguresWord={props.downloadFiguresWord} />
                <Sidebarify drawerOpen={props.drawerOpen} toggleDrawer={props.toggleDrawer}>
                    <BetaDisplay
                        key={props.patentId + props.currentlySelectedNode.id}
                        activeSequence={fullSequenceData.activeSequence}
                        patentId={props.patentId}
                        enableEditing={enableEditing}
                        patentStep={props.currentlySelectedNode.id}
                        currentStep={currentStep}
                        variables={filteredVars}
                        newKeys={newKeys}
                        parsedClaimList={parsedClaimList}
                        allVariables={variables}
                        currentState={currentState}
                        figuresActive={figuresActive}
                        setActiveFigures={setActiveFigures}
                    />
                </Sidebarify>

            </div>)
        }
    }
    if (props.currentlySelectedNode.data.store?.general) {
        return (
            <div style={{ display: 'flex', width: "100%", height: '100%' }}>
                <LoadingModal loading={loading} />
                <EditableModal editDisplayTxt={editDisplayTxt} enabled={editMode} varText={editMode ? variables[editVariable] : ""} submitEdits={submitEdits} />
                <BackButtonModal
                    patentId={props.patentId}
                    backModalOpen={backModalOpen}
                    setBackModalOpen={setBackModalOpen}
                    backButtonHit={backButtonHit}
                />
                <FormPage
                    startPage={props.currentlySelectedNode.id === "node-2"}
                    key={props.currentlySelectedNode}
                    updateState={updateState}
                    backButtonHit={(() => { setBackModalOpen(true) })}
                    general={props.currentlySelectedNode.data.store.general}
                    userInputs={props.currentlySelectedNode.data.store.userInputs}
                    continueSequence={continueSequence}
                />
                <Sidebarify drawerOpen={props.drawerOpen} toggleDrawer={props.toggleDrawer}>
                    <BetaDisplay
                        key={props.patentId + props.currentlySelectedNode.id}
                        activeSequence={fullSequenceData.activeSequence}
                        patentId={props.patentId}
                        enableEditing={enableEditing}
                        currentStep={currentStep}
                        parsedClaimList={parsedClaimList}
                        variables={filteredVars}
                        newKeys={newKeys}
                        currentState={currentState}
                        figuresActive={figuresActive}
                        allVariables={variables}
                        setActiveFigures={setActiveFigures}
                    />
                </Sidebarify>

            </div>
        )
    } else {
        return <div />
    }
}

export default SequenceHandler
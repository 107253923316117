import React, { useState, useEffect, useContext } from 'react';

import DesContext from './context.js';
import WorkflowContext from '../workflow/context.js';
import APIContext from '../api/context.js';
import { useFileHandlers } from './hooks.js';
import { useMockDialog } from '../api/hooks.js';
const DesContextProvider = ({ children }) => {
    const { api, apiWithLoading } = useContext(APIContext)
    const { activeWorkflow, manager } = useContext(WorkflowContext);
    const [steps, setSteps] = useState([]);
    const [stepData, setStepData] = useState({});
    const [isRunning, setIsRunning] = useState(false)
    const [previewMode, setPreviewMode] = useState(false);
    const [previewModeInit, setPreviewModenit] = useState(false);
    const [previewModeActiveStep, setPreviewModeActiveStep] = useState("");
    const [evaluatonSteps, setEvaluatonSteps] = useState([]);
    const [stepEvaluationData, setStepEvaluationData] = useState({});
    const [testProfiles, setTestProfiles] = useState({});
    const [activeTestProfile, setActiveTestProfile] = useState([]);
    const [activeTestProfileMeta, setActiveTestProfileMeta] = useState({});
    const [activeTestProfileId, setActiveTestProfileId] = useState("Default Profile");
    const [flowName, setFlowName] = useState("");
    const [workflowVersion, setWorkflowVersion] = useState(1)
    const [staticFiles, setStaticFiles] = useState([])
    const [saveWorkflowVer, setSaveWorkflowVer] = useState(1);
    const allStepsData = stepData
    const [loadRefreshWorkflow, setRefreshWorkflow] = useState(1);
    const [model, setModel] = useState("GPT-4o");
    console.log('hello',activeWorkflow)
    console.log(stepEvaluationData)
    console.log(stepData)
    console.log(activeTestProfile)
    console.log(staticFiles)
    useEffect(() => {
        if (previewMode) {
            setPreviewModenit(true)
        }
    },
        [previewMode])

    useEffect(() => {
        if (previewModeInit) {
            console.log(steps)
            setPreviewModenit(false)
            if (steps.length > 0) {
                setPreviewModeActiveStep(steps[0])
                console.log("resetting?")
                console.log(steps[0])
            }
        }
    },
        [previewModeInit, steps])


    const importWorkflow = (flow) => {
        let flowMetaData = flow.metadata
        setFlowName(flow.name)
        manager.setActiveWorkflowName(flow.name)
        setSteps(flowMetaData?.steps ? flowMetaData.steps : [])
        setStepData(flowMetaData?.stepData ? flowMetaData.stepData : {})
        setTestProfiles(flowMetaData?.testProfiles ? flowMetaData.testProfiles : {})
        setActiveTestProfile(flowMetaData?.activeTestProfile ? flowMetaData.activeTestProfile : [])
        setActiveTestProfileMeta(flowMetaData?.activeTestProfileMeta ? flowMetaData.activeTestProfileMeta : {})
        setActiveTestProfileId(flowMetaData?.activeTestProfileId ? flowMetaData.activeTestProfileId : "Default Profile")
        setWorkflowVersion(flowMetaData?.workflowVersion ? flowMetaData.workflowVersion : 1)
        setModel(flowMetaData?.model ? flowMetaData.model : 'GPT-4o')
        setTimeout(() => {
            setSaveWorkflowVer(v => v + 1)
        }, 1000)
    }

    const { handleFileLoad, downloadObjectAsJson } = useFileHandlers(importWorkflow);

    const saveWorkflow = () => setSaveWorkflowVer(old => old + 1)
    useEffect(() => {
        if (activeWorkflow !== null) {
            console.log('froeg dopey', model)
            manager.updateWorkflow(steps, stepData, testProfiles, activeTestProfileId, activeTestProfile, activeTestProfileMeta, workflowVersion, model)
        }
    }, [saveWorkflowVer])

    const exportWorkflow = () => {
        api('/ptntst/getWorkflow', { flowId: activeWorkflow }).then(res => {
            downloadObjectAsJson({ flow: res.data.flow }, `${res.data.flow.name}.json`)
        })
    }
    useEffect(() => {
        setPreviewMode(false)
    }, [activeWorkflow])

    useEffect(() => {
        if (activeWorkflow !== null) {
            api('/ptntst/getWorkflow', { flowId: activeWorkflow }).then(res => {
                let flowMetaData = res.data.flow.metadata
                setFlowName(res.data.flow.name)
                manager.setActiveWorkflowName(res.data.flow.name)
                setSteps(flowMetaData?.steps ? flowMetaData.steps : [])
                setStepData(flowMetaData?.stepData ? flowMetaData.stepData : {})
                setTestProfiles(flowMetaData?.testProfiles ? flowMetaData.testProfiles : {})
                setActiveTestProfile(flowMetaData?.activeTestProfile ? flowMetaData.activeTestProfile : [])
                setActiveTestProfileMeta(flowMetaData?.activeTestProfileMeta ? flowMetaData.activeTestProfileMeta : {})
                setActiveTestProfileId(flowMetaData?.activeTestProfileId ? flowMetaData.activeTestProfileId : "Default Profile")
                setWorkflowVersion(flowMetaData?.workflowVersion ? flowMetaData.workflowVersion : 1)
                setStaticFiles(flowMetaData?.staticFiles ? flowMetaData.staticFiles : [])
                console.log('froeg says ',flowMetaData?.model)
                setModel(flowMetaData?.model ? flowMetaData.model : 'GPT-4o')
            })
        }
    }, [activeWorkflow, loadRefreshWorkflow])


    const saveProfileAndUpdate = () => { }

    const addStep = (index) => {
        let newId = Math.random().toString(36).substring(7);
        setSteps(prevSteps => {
            setStepData(prevData => {
                let newData = { ...prevData };
                newData[newId] = { initialized: false, display: "overview", title: "", files: [] };
                return newData;
            });
            let newSteps = [...prevSteps];
            if (index > prevSteps.length - 1) {
                newSteps = [...newSteps, newId]
            } else {
                newSteps.splice(index, 0, newId);
            }
            return newSteps
        });
        setActiveTestProfile(prevActiveTestProfile => {
            let stepTestData = { stepId: newId, state: {} }
            let newActiveTestProfile = [...prevActiveTestProfile];
            if (index > prevActiveTestProfile.length - 1) {
                newActiveTestProfile = [...newActiveTestProfile, stepTestData]
            } else {
                newActiveTestProfile.splice(index, 0, stepTestData);
            }
            return newActiveTestProfile
        });
    };

    const removeStep = useMockDialog(
        "Are you sure you wish to delete this step? After you take this action, the step cannot be recovered.",
        'Delete Step',
        (index) => {
            setSteps(prevSteps => {
                let newSteps = [...prevSteps];
                newSteps.splice(index, 1);
                setStepData(prevData => {
                    let newData = { ...prevData };
                    delete newData[prevSteps[index]];
                    return newData;
                });
                return newSteps;
            });
            setActiveTestProfile(prevActiveTestProfile => {
                let newActiveTestProfile = [...prevActiveTestProfile];
                newActiveTestProfile.splice(index, 1);
                return newActiveTestProfile
            });
        }, 'singleWorkflow', 'deleteStep', true)

    const fastForwardStep = (index) => {
        saveWorkflow()
        // let dummyTestProfile = Object.keys(stepData).map((stepId)=>({ stepId: stepId, state: {} }))
        setIsRunning(true)
        api('/ptntst/testExecution', { steps: steps, stepData: stepData, index: index, stateDataArray: JSON.stringify(activeTestProfile), staticFiles: staticFiles, model: model }).then(res => {
            setIsRunning(false)
            setActiveTestProfile(res.data.history)
        }).catch((e) => {
            console.log(e)
            setIsRunning(false)
        })
    }

    const playStep = (index) => {
        saveWorkflow()
        setIsRunning(true)
        api('/ptntst/debugExecution', { steps: steps, stepData: stepData, index: index, stateDataArray: JSON.stringify(activeTestProfile), staticFiles: staticFiles, model: model }).then(res => {
            setIsRunning(false)
            setActiveTestProfile(res.data.history)
        }).catch((e) => {
            console.log(e)
            setIsRunning(false)
        })
    }

    const uploadFiles = (sData) => (acceptedFiles) => {
        acceptedFiles.map((acceptedFile) => {
            const formData = new FormData();
            formData.append('file', acceptedFile);
            formData.append('flowId', activeWorkflow);
            apiWithLoading('/ptntst/uploadFile', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(res => {
                sData.files.push({
                    blobName: res.data.blobName,
                    origName: res.data.origName,
                    mimeType: res.data.mimeType,
                    flowId: activeWorkflow
                })
                saveWorkflow()
            })
        })
    }

    const uploadTemplate = (sData) => (acceptedFiles) => {
        acceptedFiles.map((acceptedFile) => {
            const formData = new FormData();
            formData.append('file', acceptedFile);
            formData.append('flowId', activeWorkflow);
            apiWithLoading('/ptntst/uploadFile', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(res => {
                sData.template = {
                    blobName: res.data.blobName,
                    origName: res.data.origName,
                    mimeType: res.data.mimeType,
                    flowId: activeWorkflow
                }

                // setStepData(flowMetaData?.stepData ? flowMetaData.stepData : {})
                
                saveWorkflow()
                console.log(sData)
            })
        })
    }

    const downloadCompletedTemplate = (sData) => () => {
        const formData = new FormData();
        formData.append('flowId', activeWorkflow);
        console.log(sData)

        // apiWithLoading('/ptntst/downloadCompletedTemplate', formData, {
        //     headers: {
        //         'Content-Type': 'multipart/form-data'
        //     }
        // }).then(res => {
        //     //Do something here...
        // })
    }

    const uploadStaticFiles = async (acceptedFiles) => {
        for (var i=0; i < acceptedFiles.length; i++) {
            let acceptedFile = acceptedFiles[i];
            let formData = new FormData();
            formData.append('file', acceptedFile);
            formData.append('flowId', activeWorkflow);
            await apiWithLoading('/ptntst/uploadStaticFile', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            setRefreshWorkflow(v => v + 1)

        }
        
    }

    const deleteFile = async (fileIndex, stepId) => {
        const formData = new FormData();
        const fileData = stepData[stepId].files[fileIndex];
        console.log(fileData);
        formData.append('flowId', activeWorkflow);
        formData.append('fileData', JSON.stringify(fileData));
        formData.append('fileIndex', fileIndex);
        api('/ptntst/deleteFile', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(res => {
            delete stepData[stepId].files.splice(fileIndex, 1);
            saveWorkflow()
        });
    };

    const deleteStaticFile = async (fileIndex, stepId) => {
        const formData = new FormData();
        const fileData = staticFiles[fileIndex]
        formData.append('flowId', activeWorkflow);
        formData.append('fileData', JSON.stringify(fileData));
        formData.append('fileIndex', fileIndex);
        api('/ptntst/deleteStaticFile', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(res => {
            setRefreshWorkflow(v => v + 1)
            //delete staticFiles.splice(fileIndex, 1);
            //saveWorkflow()
        });
    };

    const executePreviewSequence = (stepId, noAdvance) => () => {
        let index = steps.indexOf(stepId)
        setIsRunning(true)
        apiWithLoading('/ptntst/debugExecution', { steps: steps, stepData: stepData, index: index, stateDataArray: activeTestProfile, staticFiles: staticFiles }).then(res => {
            if (index + 1 < steps.length && !noAdvance) {
                setPreviewModeActiveStep(steps[index + 1])
            }
            setIsRunning(false)
            setActiveTestProfile(res.data.history)
        }).catch((e) => { })

    }

    const simpleAdvanceSeq = (stepId) => () => {
        let index = steps.indexOf(stepId)
        if (index + 1 < steps.length) {
            setPreviewModeActiveStep(steps[index + 1])
        }
    }
    
    const fastForwardStepStatus = (index) => { }
    const playStepStatus = (index) => { }
    const validateStepSequence = () => { }
    const createStepOperations = ({ stepData, stepIndex, stepId }) => {
        return {
            stepData,
            stepIndex,
            stepId,
            activeTestProfileId,
            testState: activeTestProfile?.[stepIndex]?.state,
            configureStep: {
                set: (data) => {
                    setStepData(prevData => {
                        let newData = { ...prevData };
                        newData[stepId] = { ...newData[stepId], ...data };
                        return newData;
                    });
                },
                setTest: (data) => {
                    setActiveTestProfile(prevActiveTestProfile => {
                        // console.log(prevActiveTestProfile)
                        let newActiveTestProfile = [...prevActiveTestProfile];
                        newActiveTestProfile[stepIndex].state = { ...newActiveTestProfile[stepIndex].state, ...data };
                        return newActiveTestProfile;
                    });
                },
                priorConversations: () => {
                    let validPriorConversations = steps.map((step, index) => [step, index]).filter((stepWrap, idx) => {
                        return idx < stepIndex && allStepsData?.[stepWrap[0]]?.name === "generativeStep"
                    })
                    return validPriorConversations;
                },
            },

            errorDisplay: ({ blah }) => {

            }
        }
    }
    const createStepOperationsById = (stepId) => {
        let stepIndex = steps.indexOf(stepId)
        return createStepOperations({ stepData: stepData[stepId], stepIndex, stepId })
    }
    const previewStepIndex = steps.indexOf(previewModeActiveStep)

    const LLMOptions = ["Gemini", "GPT-4o"]
    const handleLLMChange = (e) => {
        setModel(e.target.value)
        setTimeout(() => {
            setSaveWorkflowVer(v => v + 1)
        }, 100)
    }
    return (
        <DesContext.Provider value={{
            manager: {
                uploadFiles, deleteFile, deleteStaticFile,
                steps, stepData, evaluatonSteps, stepEvaluationData, previewMode, previewModeActiveStep,
                addStep, removeStep, fastForwardStep, fastForwardStepStatus, isRunning, downloadCompletedTemplate,
                staticFiles, uploadStaticFiles, LLMOptions, currentLLM:model, handleLLMChange, uploadTemplate,
                createStepOperations, setPreviewMode, playStep, playStepStatus, exportWorkflow, handleFileLoad,
                createStepOperationsById, executePreviewSequence, simpleAdvanceSeq, previewStepIndex, model
            }
        }}>
            {children}
        </DesContext.Provider>
    );
}

export default DesContextProvider;
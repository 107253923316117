import {useContext } from 'react';
import LexFileDropzone from '../../Lex/LexFileDropzone';
import DesContext from '../../../contexts/singleFlow/context';
import { Typography } from '@mui/material';

const DocGenUploadCollector = ({ configureStep, stepData, errorDisplay, stepIndex, stepId }) => {
    let { manager} = useContext(DesContext);
    return <div>
        {stepData.template? <Typography sx={{fontWeight:"500"}} variant="body1">Currently utilizing {stepData.template?.origName}</Typography>:null}
        <LexFileDropzone 
        files={stepData?.files ? stepData.files : []}
        uploadFuncton={manager.uploadTemplate(stepData)}
        dependencies={[configureStep, stepData]}
        deleteFunction={(index)=>()=>manager.deleteFile(index, stepId)} 
        />
    </div>
}

export default DocGenUploadCollector;
import {useContext } from 'react';
import LexFileDropzone from '../../Lex/LexFileDropzone';
import DesContext from '../../../contexts/singleFlow/context';

const DynamicFileCollector = ({ configureStep, stepData, errorDisplay, stepIndex, stepId }) => {
    let { manager} = useContext(DesContext);
    return <div>
        <LexFileDropzone 
        files={stepData?.files ? stepData.files : []}
        uploadFuncton={manager.uploadFiles(stepData)}
        dependencies={[configureStep, stepData]}
        deleteFunction={(index)=>()=>manager.deleteFile(index, stepId)} 
        />
    </div>
}

export default DynamicFileCollector;

import LexTextField from '../../Lex/LexTextField';
import LexTypography from '../../Lex/LexTypography';
import { InputAdornment } from '@mui/material';

const outerStyle = {
    // marginTop: "5px", 
    // backgroundColor: "rgb(0,0,0,.1)", 
    borderRadius: "8px",
    // paddingTop: "15px", 
    paddingBottom: "15px",
    // paddingLeft: "15px" 
}

const sampleInputField = {
    multiline: true,
    rows: 6,
    inputLabel: "Sample Input",
    value: "",
    onChange: (e) => { },
    helperText: ""
}

const Collector = ({ titleText, descriptionText, inputFields }) => {
    return <div style={outerStyle}>
        {titleText ? <div style={{ paddingBottom: "20px" }}>
            <LexTypography variant="h6">{titleText}</LexTypography>
        </div> : null}
        {descriptionText ? <div style={{ paddingBottom: "20px" }}>
            <LexTypography variant="body1">{descriptionText}</LexTypography>
        </div> : null}
        {inputFields.map(inputField => {
            return <div><LexTextField
                id="filled-basic"
                multiline={inputField.multiline}
                rows={inputField.rows}
                label={inputField.inputLabel}
                value={inputField.value}
                onChange={inputField.onChange}
                variant="filled"
                InputProps={
                    (inputField.targetName === "varName"
                        || inputField.targetName === "fileVarName")
                        ? { startAdornment: <InputAdornment position="start" sx={{ marginRight: '0' }}>$</InputAdornment> }
                        : {}}
                helperText={inputField.helperText}
            /> </div>
        })
        }
    </div>
};

export default Collector;